<template>
  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->
  <router-view/>
</template>

<style>

.v-btn--variant-plain {
    background: #333399!important;
    color: #ffffff!important;
}
.v-btn--variant-elevated, .v-btn--variant-flat {
    background: #333399!important;
    color: #ffffff!important;
}
nav {
  padding: 20px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #333399;
}
</style>
